// SPDX-FileCopyrightText: 2017-2022 City of Espoo
//
// SPDX-License-Identifier: LGPL-2.1-or-later

import React from 'react'

interface Props {
  color?: string
}

export const EvakaLogo = React.memo(function EvakaLogo({
  color = '#FFF'
}: Props) {
  return (
    <svg
      width="117"
      height="24"
      viewBox="0 0 117 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.955 12.6686H3.06898C3.22637 15.0293 4.13132 16.9178 5.78385 18.3735C7.39703 19.8293 9.48237 20.5375 11.9612 20.5375C13.3776 20.5375 14.6367 20.3014 15.8171 19.7899C16.2892 19.5932 16.722 19.3572 17.1548 19.0817C18.2172 18.3735 19.673 18.4916 20.4992 19.4752L20.5779 19.5539C19.5943 20.7735 18.3352 21.6785 16.8401 22.308C15.3449 22.9375 13.6924 23.2522 11.8825 23.2522C9.56106 23.2522 7.51507 22.7408 5.70516 21.7572C3.93459 20.7735 2.51814 19.3965 1.53449 17.6653C0.511497 15.8949 0 13.9276 0 11.7244C0 9.52109 0.472151 7.55387 1.4558 5.78338C2.43945 4.01289 3.73786 2.67518 5.39039 1.69158C7.08227 0.707971 8.93152 0.23584 11.0169 0.23584C13.1022 0.23584 14.9908 0.707971 16.6433 1.69158C18.2959 2.67518 19.5943 4.01289 20.5386 5.74404C21.4829 7.47518 21.955 9.4424 21.955 11.685V12.6686ZM5.58712 4.95715C4.13132 6.3342 3.26571 8.14404 3.06898 10.3867H19.0828C18.886 8.14404 18.0598 6.3342 16.5646 4.95715C15.1088 3.5801 13.2596 2.87191 11.0562 2.87191C8.85283 2.87191 7.04292 3.5801 5.58712 4.95715Z"
        fill={color}
      />
      <path
        d="M47.7658 0.236122L36.9457 22.8984H33.8374L24.4731 3.26563C23.8042 1.84924 24.8272 0.236122 26.401 0.236122L35.4506 18.964L44.5788 0.196777L47.7658 0.236122Z"
        fill={color}
      />
      <path
        d="M66.0224 2.5575C67.5963 4.01324 68.3439 6.17717 68.3439 9.0493V22.8985C66.7307 22.8985 65.4716 21.6001 65.4716 20.0264V19.4362C64.8027 20.6165 63.7797 21.5214 62.4813 22.1509C61.1829 22.8198 59.6091 23.1345 57.7991 23.1345C55.3204 23.1345 53.3137 22.5444 51.8579 21.3641C50.4021 20.1837 49.6545 18.61 49.6545 16.6427C49.6545 14.7542 50.3234 13.2198 51.7005 12.0395C53.0776 10.8591 55.281 10.3083 58.2713 10.3083H65.3536V8.93127C65.3536 7.0034 64.8027 5.54766 63.7404 4.52471C62.678 3.50176 61.1042 3.02963 59.0189 3.02963C57.6024 3.02963 56.2253 3.2657 54.9269 3.73783C53.6285 4.20996 52.4874 4.83947 51.5825 5.6657L50.2054 3.42307C51.3464 2.47881 52.6842 1.73127 54.2974 1.18045C55.8712 0.668976 57.5631 0.393566 59.2943 0.393566C62.2059 0.354222 64.4486 1.10176 66.0224 2.5575ZM62.6387 19.5542C63.8584 18.7673 64.7634 17.6657 65.3536 16.1706V12.5116H58.35C54.5334 12.5116 52.6055 13.8493 52.6055 16.5247C52.6055 17.8231 53.117 18.846 54.1006 19.5936C55.0843 20.3411 56.4614 20.7345 58.2713 20.7345C59.9238 20.7345 61.419 20.3411 62.6387 19.5542Z"
        fill={color}
      />
      <path
        d="M83.9249 12.2754L77.5509 19.0033V23.0951H74.5212V0.393443V0C76.1738 0 77.5509 1.77049 77.5509 3.42295V14.8328L91.322 0.393443H95.0598L86.0496 10.1115L95.532 23.0951H91.8335L83.9249 12.2754Z"
        fill={color}
      />
      <path
        d="M114.497 2.6359C116.07 4.09163 116.818 6.25557 116.818 9.1277V22.9769C115.205 22.9769 113.946 21.6785 113.946 20.1047V19.5146C113.277 20.6949 112.254 21.5998 110.955 22.2293C109.657 22.8982 108.083 23.2129 106.273 23.2129C103.794 23.2129 101.788 22.6228 100.332 21.4425C98.8762 20.2621 98.1287 18.6884 98.1287 16.7211C98.1287 14.8326 98.7975 13.2982 100.175 12.1179C101.552 10.9375 103.755 10.3867 106.745 10.3867H113.828V9.00967C113.828 7.0818 113.277 5.62606 112.215 4.60311C111.152 3.58016 109.578 3.10803 107.493 3.10803C106.077 3.10803 104.699 3.34409 103.401 3.81622C102.103 4.28835 100.962 4.91786 100.057 5.74409L98.6795 3.50147C99.8205 2.47852 101.158 1.73098 102.732 1.2195C104.306 0.708027 105.998 0.432617 107.729 0.432617C110.68 0.432617 112.923 1.18016 114.497 2.6359ZM111.073 19.6326C112.293 18.8457 113.198 17.7441 113.788 16.249V12.59H106.785C102.968 12.59 101.04 13.9277 101.04 16.6031C101.04 17.9015 101.552 18.9244 102.535 19.672C103.519 20.4195 104.896 20.8129 106.706 20.8129C108.398 20.7736 109.854 20.4195 111.073 19.6326Z"
        fill={color}
      />
    </svg>
  )
})
