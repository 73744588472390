// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * SPDX-FileCopyrightText: 2017-2021 City of Espoo
 *
 * SPDX-License-Identifier: LGPL-2.1-or-later
 */

body {
  font-family: 'Open Sans', 'Arial', sans-serif;
  background-color: #f5f5f5;
  margin: 0;
}

@media print {
  body {
    height: unset;
  }
}

input,
button,
textarea,
select {
  font: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #0f0f0f;
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

a {
  color: #0050bb;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
}

#app {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./index.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,6CAA6C;EAC7C,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,eAAe;EACf,kCAAkC;EAClC,mCAAmC;EACnC,kBAAkB;EAClB,kBAAkB;EAClB,kCAAkC;EAClC,8BAAsB;KAAtB,2BAAsB;UAAtB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,6BAAqB;EAArB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd","sourcesContent":["/*\n * SPDX-FileCopyrightText: 2017-2021 City of Espoo\n *\n * SPDX-License-Identifier: LGPL-2.1-or-later\n */\n\nbody {\n  font-family: 'Open Sans', 'Arial', sans-serif;\n  background-color: #f5f5f5;\n  margin: 0;\n}\n\n@media print {\n  body {\n    height: unset;\n  }\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: inherit;\n}\n\nhtml {\n  box-sizing: border-box;\n  background-color: #f5f5f5;\n  color: #0f0f0f;\n  font-size: 100%;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-font-smoothing: antialiased;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  text-rendering: optimizeLegibility;\n  text-size-adjust: 100%;\n}\n\na {\n  color: #0050bb;\n  cursor: pointer;\n  text-decoration: none;\n}\n\n#app {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
